<template>
  <div class="base-cars-list">
    <b-card>
      <authorization-guard
        permission="base.transport.index"
        silent
      >
        <base-transport-list-filtration />
      </authorization-guard>

      <div class="buttons">
        <vs-button
          @click="openTransportCreateModal"
          vs-variant="primary"
          v-can:store="'base.transport'"
        >
          + {{ $t('base.transport.list.button.addTransport') }}
        </vs-button>
      </div>
      <br>

      <authorization-guard
        permission="base.transport.index"
      >
        <base-transport-list-table />
      </authorization-guard>

      <!-- Modals -->
      <base-transport-create-modal
        @ok="fetchData"
      />
      <base-resource-edit-modal
        type="car"
        @ok="fetchData"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import baseTransportTable from '@/hooks/tables/base/transport';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import BaseResourceEditModal from '@/components/views/base/resource/modals/BaseResourceEditModal.vue';
import BaseTransportListTable from '@/components/views/base/transport/BaseTransportListTable.vue';
import BaseTransportCreateModal from '@/components/views/base/transport/modals/BaseTransportCreateModal.vue';
import BaseTransportListFiltration from '@/components/views/base/transport/BaseTransportListFiltration.vue';

export default {
  components: {
    VsButton,

    BCard,
    AuthorizationGuard,
    BaseResourceEditModal,
    BaseTransportListTable,
    BaseTransportCreateModal,
    BaseTransportListFiltration,
  },
  setup() {
    const { modalCreateTransport } = useBaseModals();
    const openTransportCreateModal = () => {
      modalCreateTransport.value.open();
    };

    const { fetchData } = baseTransportTable.value;

    return {
      openTransportCreateModal,
      fetchData,
    };
  },
};
</script>
