<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            label="Inwestycja początkowa"
            >
            <investments-investment-picker
              multiple
              v-model="filters.from_investment_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Inwestycja docelowa"
            >
            <investments-investment-picker
              multiple
              v-model="filters.to_investment_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Status"
          >
            <base-transport-status-picker
              multiple
              v-model="filters.status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data od"
          >
            <vs-datepicker
              v-model="filters.transport_date_from"
              resetButton
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Data do"
          >
            <vs-datepicker
              v-model="filters.transport_date_to"
              resetButton
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Region"
          >
            <core-branch-picker
              multiple
              v-model="filters.region"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import baseTransportTable from '@/hooks/tables/base/transport';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import BaseTransportStatusPicker from '@/components/module/base/BaseTransportStatusPicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';

export default {
  name: 'CarsListFiltration',
  setup() {
    const { table, filters, clearAllFilters } = baseTransportTable.value;

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    InvestmentsInvestmentPicker,
    BaseTransportStatusPicker,
    VsDatepicker,
    CoreBranchPicker,
  },
};
</script>
