<template>
  <div class="base-cars-list-table">
    <authorization-guard
      permission="base.resource.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="$store.state.base.transport.transport"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >
          <span v-if="column.field === 'name'">
            <div class="transport-name">
              {{ row.name }}
            </div>
            <base-transport-route-label
              :start="row.from_investment"
              :destination="row.to_investment"
              size="sm"
            />
          </span>
          <span v-if="column.field === 'date'">
            {{ formatDate.transport(row.transport_date) }}
          </span>
          <span v-if="column.field === 'status'">
            <vs-status-badge
              :data="$store.getters['base/transport/status/getStatusBySlug'](row.status)"
            />
          </span>
          <span v-if="column.field === 'user'">
            <user-label
              withAvatar
              nameOnly
              :data="row.author"
              v-if="row.author"
            />
          </span>
          <span v-if="column.field === 'dispatcher'">
            <user-label
              withAvatar
              nameOnly
              :data="row.release"
              v-if="row.release"
            />
          </span>
          <span v-if="column.field === 'receiver'">
            <user-label
              withAvatar
              nameOnly
              :data="row.receiver"
              v-if="row.receiver"
            />
          </span>
          <span v-if="column.field === 'branch'">
            {{ $store.getters['branches/byId'](row.core_branch_id)?.shortcut }}
          </span>
        </template>
      </vs-table>
    </authorization-guard>

    <base-transport-edit-modal
      @ok="fetchData"
    />
  </div>
</template>

<script>

import VsTable from '@/components/ui/vs-table/Table.vue';
import baseTransportTable from '@/hooks/tables/base/transport';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseTransportRouteLabel from '@/components/module/base/BaseTransportRouteLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import formatDate from '@/libs/date-formatter';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import BaseTransportEditModal from './modals/BaseTransportEditModal.vue';

export default {
  name: 'MachinesListTable',
  components: {
    VsTable,
    AuthorizationGuard,
    BaseTransportRouteLabel,
    UserLabel,
    VsStatusBadge,
    BaseTransportEditModal,
  },
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = baseTransportTable.value;

    fetchData();

    const { modalEditTransport } = useBaseModals();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditTransport.value.open({ ...obj });
        },
      },
    ];

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      fetchData,
    };
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'base.transport.details', params: { id: data.row.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.transport-name {
  text-transform: uppercase;
  font-weight: 500;
}
</style>
