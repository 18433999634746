<template>
  <vs-modal
    :modalInstance="modalCreateTransport"
    @ok="createResource"
  >
    <base-transport-form
      v-model="fields"
      :feedback="feedback"
      @resources:update="(resources) => fields.resources = resources"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { CreateTransportError, CreateTransportSuccess } from '@/libs/toasts/feedback/module/base';
import BaseTransportForm from '../forms/BaseTransportForm.vue';

export default {
  name: 'BaseTransportCreateModal',
  emits: ['ok'],
  setup(_, { emit }) {
    const { modalCreateTransport } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      setFieldDefaults,
    } = useValidatedForm({
      name: 'required',
      status: 'required',
      from_investment_id: '',
      to_investment_id: '',
      crm_provider_id: 'required',
      core_branch_id: 'required',
      base_transport_type_id: 'required',
      release_id: 'required',
      receiver_id: 'required',
      transport_date: 'required',
      delivery_date: 'required|date_after:@transport_date',
      resources: '',
    });

    setFieldDefaults({ status: 'new', base_transport_type_id: 1 });

    modalCreateTransport.value.on({
      close() {
        resetFields();
      },
    });

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateTransport.value.isLoading = true;

      const sendData = {
        ...fields.value,
      };

      useBaseApi()
        .createTransport({
          ...sendData,
        })
        .then(({ data }) => {
          showToast(CreateTransportSuccess, { name: data.data?.name });
          modalCreateTransport.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(CreateTransportError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateTransport.value.isLoading = false;
        });
    };

    return {
      modalCreateTransport,
      fields,
      feedback,
      createResource,
    };
  },
  components: {
    VsModal,
    BaseTransportForm,
  },
};
</script>
