import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseTransportColumns = [
  {
    label: i18n.t('base.transport.field.name'),
    field: 'name',
  },
  {
    label: i18n.t('base.transport.field.status'),
    field: 'status',
  },
  {
    label: i18n.t('base.global.field.branch'),
    field: 'branch',
  },
  {
    label: i18n.t('base.transport.field.date'),
    field: 'date',
  },
  {
    label: i18n.t('base.transport.field.user'),
    field: 'user',
  },
  {
    label: i18n.t('base.transport.field.dispatcher'),
    field: 'dispatcher',
  },
  {
    label: i18n.t('base.transport.field.receiver'),
    field: 'receiver',
  },
];

export default ref(createTable('base/transport', baseTransportColumns, {
  filters: {
    from_investment_id: [],
    to_investment_id: [],
    status: [],
    region: [],
    transport_date_from: '',
    transport_date_to: '',
  },
}));
