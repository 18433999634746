<template>
  <base-transport-list />
</template>

<script>
import BaseTransportList from './BaseTransportList.vue';

export default {
  name: 'BaseTransport',
  setup() {
    return {};
  },
  components: {
    BaseTransportList,
  },
};
</script>

<style scoped>

</style>
