var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Inwestycja początkowa"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.from_investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "from_investment_id", $$v);
      },
      expression: "filters.from_investment_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Inwestycja docelowa"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.to_investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "to_investment_id", $$v);
      },
      expression: "filters.to_investment_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('base-transport-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.status,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status", $$v);
      },
      expression: "filters.status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data od"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "resetButton": ""
    },
    model: {
      value: _vm.filters.transport_date_from,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "transport_date_from", $$v);
      },
      expression: "filters.transport_date_from"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data do"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "resetButton": ""
    },
    model: {
      value: _vm.filters.transport_date_to,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "transport_date_to", $$v);
      },
      expression: "filters.transport_date_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Region"
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.region,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "region", $$v);
      },
      expression: "filters.region"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }