var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-list"
  }, [_c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "base.transport.index",
      "silent": ""
    }
  }, [_c('base-transport-list-filtration')], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.transport',
      expression: "'base.transport'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openTransportCreateModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.transport.list.button.addTransport')) + " ")])], 1), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "base.transport.index"
    }
  }, [_c('base-transport-list-table')], 1), _c('base-transport-create-modal', {
    on: {
      "ok": _vm.fetchData
    }
  }), _c('base-resource-edit-modal', {
    attrs: {
      "type": "car"
    },
    on: {
      "ok": _vm.fetchData
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }