var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-list-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.transport.transport,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _vm$$store$getters$br;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_c('div', {
          staticClass: "transport-name"
        }, [_vm._v(" " + _vm._s(row.name) + " ")]), _c('base-transport-route-label', {
          attrs: {
            "start": row.from_investment,
            "destination": row.to_investment,
            "size": "sm"
          }
        })], 1) : _vm._e(), column.field === 'date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.transport(row.transport_date)) + " ")]) : _vm._e(), column.field === 'status' ? _c('span', [_c('vs-status-badge', {
          attrs: {
            "data": _vm.$store.getters['base/transport/status/getStatusBySlug'](row.status)
          }
        })], 1) : _vm._e(), column.field === 'user' ? _c('span', [row.author ? _c('user-label', {
          attrs: {
            "withAvatar": "",
            "nameOnly": "",
            "data": row.author
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'dispatcher' ? _c('span', [row.release ? _c('user-label', {
          attrs: {
            "withAvatar": "",
            "nameOnly": "",
            "data": row.release
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'receiver' ? _c('span', [row.receiver ? _c('user-label', {
          attrs: {
            "withAvatar": "",
            "nameOnly": "",
            "data": row.receiver
          }
        }) : _vm._e()], 1) : _vm._e(), column.field === 'branch' ? _c('span', [_vm._v(" " + _vm._s((_vm$$store$getters$br = _vm.$store.getters['branches/byId'](row.core_branch_id)) === null || _vm$$store$getters$br === void 0 ? void 0 : _vm$$store$getters$br.shortcut) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('base-transport-edit-modal', {
    on: {
      "ok": _vm.fetchData
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }